<template>
  <div>
    <CommonIndex :pageType="pageType" @showDetail="showDetail" />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CommonIndex from '@/views/safety-device/component/common-index';
import { createDrawer } from '@triascloud/x-components';
import NacelleDetail from './nacelle-detail';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component({
  components: {
    CommonIndex,
  },
})
export default class LiftMonitor extends Vue {
  // 吊篮监测
  pageType = pageTypeEnum.nacelleMonitor;

  showDetail(record) {
    this.modalDrawer = createDrawer(
      () => (
        <NacelleDetail
          detailData={record}
          pageType={this.pageType}
          close={this.handleCloseModalDrawer}
        />
      ),
      {
        title: this.$t('safety.deviceDetail'),
        width: '720px',
        wrapClassName: 'deviceDetail',
      },
    );
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>
