<template>
  <div :class="$style.detail">
    <DetailHeader :headerData="headerData" />
    <div>
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="device-status" tab="设备数据">
          <div v-if="activeKey === 'device-status'" :class="$style.listWrap">
            <DeviceStatus :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="device-warning">
          <span slot="tab">
            <a-badge
              style="width: 40px;"
              :count="alarmCount"
              :number-style="{
                backgroundColor: 'var(--primary)',
                boxShadow: '0 0 0 1px var(--primary) inset',
                top: '-5px',
              }"
            >
              {{ $t('safety.alarm') }}
            </a-badge>
          </span>
          <div v-if="activeKey === 'device-warning'" :class="$style.listWrap">
            <DeviceWarning :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import DetailHeader from '@/views/safety-device/component/detail-header';
import DeviceWarning from './device-warning';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import DeviceStatus from './device-status';

@Component({
  components: {
    DetailHeader,
    DeviceWarning,
    ScreenSelect,
    DeviceStatus,
  },
})
export default class LiftDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detailData;
  @Prop({ type: String, default: '' }) pageType;

  headerData = {};
  mounted() {
    this.initHeadData();
  }
  alarmCount = 0;
  deviceId = '';
  initHeadData() {
    const data = this.detailData;
    this.headerData = {
      deviceName: data.deviceName,
      deviceCode: data.deviceCode,
      bindTime: data.bindTime,
      projectName: data.projectName,
      remark: data.remark,
    };
    this.alarmCount =
      data.alarmCount !== 0 && data.alarmCount !== -1 ? data.alarmCount : 0;
    this.deviceId = data.pkId;
  }
  activeKey = 'device-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>
